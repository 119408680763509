@font-face {
  font-family: "futura-light";
  src: url("./futura-light_rhgXK/futura_light/futura_light.otf");
}

@font-face {
  font-family: "garamond-regular";
  src: url("./garamond-regular_yWpd3/garamond_regular/garamond_regular.ttf");
}

* {
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: 100%;
  position: fixed;
  background: url("./Main_background.JPG");
  background-position: center 65%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px 50px 0px;
}

.logo {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.cta {
  width: 600px;
  height: 350px;
  background: white;
  position: relative;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-x {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 15px;
  font-size: 13px;
  cursor: pointer;
}

.cta--img {
  width: 50%;
  height: 100%;
}

.cta--form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  padding: 25px 30px;
}

.cta--form h1 {
  font-family: "futura-light";
  text-transform: uppercase;
  font-size: 21px;
}

form {
  display: flex;
  flex-direction: column;
  width: 95%;
}

form input {
  font-family: "garamond-regular";
  font-size: 17px;
  padding: 10px 10px;
  margin-bottom: 8px;
  border-radius: 3px;
  outline: none;
  background: rgb(237, 233, 221);
  border: 2px solid rgb(100, 100, 100);
  color: black;
}

form input::placeholder {
  font-size: 14px;
  text-transform: uppercase;
  color: black;
}

form button {
  font-family: "garamond-regular";
  font-size: 15.5px;
  background: whitesmoke;
  color: black;
  padding: 5px 10px;
  border-radius: 3px;
  outline: none;
  text-transform: uppercase;
  border: 2px solid rgb(100, 100, 100);
  cursor: pointer;
  text-align: left;
}

.cta--form p {
  font-family: "futura-light";
  font-size: 14px;
}

.socials--text {
  margin-top: 10px;
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.socials--text-mobile {
  display: none;
}

.socials--text p {
  text-transform: uppercase;
  font-family: "garamond-regular";
  letter-spacing: .5px;
  font-weight: 500;
  color: white;
}

.fa-facebook, .fa-instagram, .fa-envelope {
  color: white;
  font-size: 30px;
  margin: 0px 5px;
}

.modal--background {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal--main {
  width: 500px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background: white;
}

.modal--main p {
  font-size: 20px;
}

.modal--main button {
  background: #A7CAE5;
  color: white;
  font-size: 18px;
  border-radius: 8px;
  outline: none;
  border: none;
  padding: 8px 20px;
  cursor: pointer;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .0), -3px -3px 10px rgba(0, 0, 0, .0);
  transition: .3s ease-in-out;
}
.modal--main button:hover {
  background: #142B56;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .175), -3px -3px 10px rgba(0, 0, 0, .175);
}

@media screen and (max-width: 890px) {
  .socials--text {
    width: 75%;
  } 
  .socials--text p {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    width: 100px;
    height: 100px;
  }
  .cta {
    width: 450px;
    height: 250px;
    padding: 3px;
  }
  .fa-x {
    margin-top: 7px;
    margin-right: 7px;
    font-size: 12px;
  }
  .cta--form {
    padding: 20px 15px;
  }
  .cta--form h1 {
    font-size: 15px;
  }
  form {
    width: 100%;
  }
  form input {
    font-size: 13px;
    padding: 6px;
    margin-bottom: 10px;
  }
  form input::placeholder {
    font-size: 11px;
  }
  form button {
    font-size: 11px;
    padding: 5px;
  }
  .cta--form p {
    font-size: 12px;
  }
  .socials--text {
    width: 65%;
  }
  .socials--text p {
    font-size: 15px;
  }
  .fa-facebook, .fa-instagram, .fa-envelope {
    font-size: 21px;
    margin: 0px 4px;
  }
}

@media screen and (max-width: 640px) {
  .socials--text {
    width: 75%;
  }
}

@media screen and (max-width: 425px) {
  .cta {
    width: 400px;
  }
  form input {
    font-size: 12px;
  }
  form input::placeholder {
    font-size: 10px;
  } 
  form button {
    font-size: 10px;
  }
  .socials--text {
    width: 90%;
  }
  .socials--text p {
    font-size: 13px;
  }
  .fa-facebook, .fa-instagram, .fa-envelope {
    font-size: 19px;
  }
  .modal--main {
    width: 300px;
    padding: 20px;
  }
  .modal--main p {
    font-size: 18px;
  }
  .modal--main button {
    font-size: 16px;
    padding: 6px 16px;
  }
}
@media screen and (max-width: 375px) {
  .cta {
    width: 365px;
  }
  form input {
    font-size: 11px;
  }
  form input::placeholder {
    font-size: 9px;
  }
  form button {
    font-size: 9px;
  }

}
@media screen and (max-width: 361px) {
  .cta {
    width: 350px;
  }
  .cta--form p {
    font-size: 11.5px;   
  }
}